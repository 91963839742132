import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./containers/Home";
import Syllabus from "./containers/Syllabus";
import Document from "./containers/Document";
import Major from "./containers/Major";
import IntroInfo from "./containers/IntroInfo";
import AlumniList from "./containers/AlumniList";
import GraToList from "./containers/GraToList";
import NewsPage from "./components/news/NewsPage";
import LecturerList from "./containers/LecturerList";
import Contact from "./containers/Contact";
import LecturerInfo from "./containers/LecturerInfo";
import NewsDetails from "./components/news/NewsDetails";
import CategoryPost from "./components/news/CategoryPost";
import CodeLabPage from "./containers/SmartDoc";
import SearchResults from "./components/news/SearchResults";
import { MeiliSearch } from "meilisearch";
import LCDLandingPage from "./components/lcd/LCDLandingPage";

const meiliSearchClient = new MeiliSearch({
  host: "https://fit.neu.edu.vn/search_api",
  apiKey: "khongdoanduocdau123",
});

const searchClient = {
  async search(requests) {
    const { indexName, params } = requests[0];
    const page = params.page || 0;
    const hitsPerPage = params.hitsPerPage || 12;
    return meiliSearchClient
      .index(indexName)
      .search(params.query, {
        limit: hitsPerPage,
        offset: page * hitsPerPage,
      })
      .then((res) => ({
        results: [
          {
            ...res,
            page: page,
            nbPages: Math.ceil(res.estimatedTotalHits / hitsPerPage),
            hitsPerPage: hitsPerPage,
          },
        ],
      }));
  },
};

function App() {
  return (
    <BrowserRouter basename="/">
      <AppRoutes />
    </BrowserRouter>
  );
}

function AppRoutes() {
  const location = window.location.pathname;
  const isCodelabPath =
    location.startsWith("/doc") || location.startsWith("/room");

  return (
    <>
      {isCodelabPath ? (
        <Routes>
          <Route path="/room/*" element={<Document />} />
          <Route path="/doc/*" element={<Document />} />
        </Routes>
      ) : (
        <Layout>
          <Routes>
            <Route path="/smartdoc/" element={<CodeLabPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/syllabus/*" element={<Syllabus />} />
            <Route path="/major/:slug" element={<Major />} />
            <Route path="/infomation" element={<IntroInfo />} />
            <Route path="/theses" element={<GraToList />} />
            <Route path="/lecturer" element={<LecturerList />} />
            <Route path="/lecturer/:slug" element={<LecturerInfo />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/alumni" element={<AlumniList />} />
            <Route path="/post/:slug" element={<NewsDetails />} />
            <Route
              path="/news"
              element={<NewsPage searchClient={searchClient} />}
            />
            <Route
              path="/news/category/:slug"
              element={<NewsPage searchClient={searchClient} />}
            />
            <Route
              path="/search"
              element={<SearchResults searchClient={searchClient} />}
            />
            <Route path="/lcd" element={<LCDLandingPage />} />
          </Routes>
        </Layout>
      )}
    </>
  );
}

export default App;
