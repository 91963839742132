import React from "react";

function Footer() {
  return (
    <div
      className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-6">
            <h4 className="text-light fw-bold mb-4">Địa chỉ</h4>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>Phòng 1310, toà A1,
              Trường Đại học Kinh tế quốc dân
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>0937638683 (TS. Phạm Xuân
              Lâm)
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>kcntt@neu.edu.vn
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <h4 className="text-light fw-bold mb-4">Thông tin</h4>
            <a className="btn btn-link" href="/lecturer">
              Giảng viên
            </a>
            <a className="btn btn-link" href="/news">
              Tin tức
            </a>
            <a className="btn btn-link" href="/contact">
              Liên hệ
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              Bản quyền thuộc về&nbsp;
              <a className="border-bottom" href="/">
                Khoa Công nghệ thông tin
              </a>
              &nbsp; &copy;
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="/">Trang chủ</a>
                <a href="/contact">Liên hệ</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
