import React from "react";
import placeholderImg from './placeholder.jpg'
import FacebookPage from "../FacebookPage";

export default function LCDLandingPage() {
  // Mock data for Executive Board
  const executiveBoard = {
    name: "Ban Công tác Đoàn & Phát triển Đảng",
    members: [
      { name: "Jane Doe", role: "President", avatar: placeholderImg },
      { name: "John Smith", role: "Vice President", avatar: placeholderImg },
      { name: "Emily Brown", role: "Treasurer", avatar: placeholderImg },
    ]
  };

  // Mock data for other boards
  const otherBoards = [
    {
      name: "Ban Truyền thông Kỹ thuật",
      members: [
        { name: "Michael Johnson", role: "Chair", avatar: placeholderImg },
        { name: "Sarah Lee", role: "Secretary", avatar: placeholderImg },
      ]
    },
    {
      name: "Ban Đối Ngoại",
      members: [
        { name: "David Wilson", role: "Director", avatar: placeholderImg },
        { name: "Emma Taylor", role: "Events Coordinator", avatar: placeholderImg },
        { name: "Ryan Garcia", role: "Communications Officer", avatar: placeholderImg },
      ]
    },
    {
      name: "Ban Văn Thể",
      members: [
        { name: "David Wilson", role: "Director", avatar: placeholderImg },
        { name: "Emma Taylor", role: "Events Coordinator", avatar: placeholderImg },
        { name: "Ryan Garcia", role: "Communications Officer", avatar: placeholderImg },
      ]
    },
    {
      name: "Ban Tổ chức sự kiện",
      members: [
        { name: "David Wilson", role: "Director", avatar: placeholderImg },
        { name: "Emma Taylor", role: "Events Coordinator", avatar: placeholderImg },
        { name: "Ryan Garcia", role: "Communications Officer", avatar: placeholderImg },
      ]
    },
  ];

  // Mock data for latest news
  const latestNews = [
    { title: "Annual General Meeting", date: "2024-05-15", summary: "Join us for our AGM to discuss the year's achievements and future plans." },
    { title: "New Student Lounge Opening", date: "2024-04-01", summary: "We're excited to announce the opening of our new student lounge in the main building." },
    { title: "Volunteer Week", date: "2024-03-20", summary: "Sign up for our annual volunteer week and make a difference in your community." },
  ];

  const notableAchievements = [
    {
      title: "Hoạt động Thể thao",
      year: "2022",
      description: "Organized a comprehensive mental health awareness campaign reaching over 10,000 students. Established a peer counseling program and secured funding for two additional full-time counselors on campus.",
      impact: "Student Welfare",
    },
  ]

  const notableAchievements2 = [
    {
      title: "Chào Tân Sinh viên",
      year: "2023",
      description: "Led a campus-wide initiative that reduced energy consumption by 30% and increased recycling rates by 50%. Implemented a new composting system in all campus cafeterias, diverting 5 tons of food waste from landfills annually.",
      impact: "Environmental Impact",
    },
    {
      title: "Chia tay Sinh viên Khoá cuối",
      year: "2022",
      description: "Organized a comprehensive mental health awareness campaign reaching over 10,000 students. Established a peer counseling program and secured funding for two additional full-time counselors on campus.",
      impact: "Student Welfare",
    },
    {
      title: "Tập huấn Nội bộ",
      year: "2022",
      description: "Organized a comprehensive mental health awareness campaign reaching over 10,000 students. Established a peer counseling program and secured funding for two additional full-time counselors on campus.",
      impact: "Student Welfare",
    },
    {
      title: "Talkshow và Workshop của Khoa CNTT",
      year: "2023",
      description: "Led a campus-wide initiative that reduced energy consumption by 30% and increased recycling rates by 50%. Implemented a new composting system in all campus cafeterias, diverting 5 tons of food waste from landfills annually.",
      impact: "Environmental Impact",
    },
    {
      title: "Giải Bóng đá Truyền thống",
      year: "2023",
      description: "Led a campus-wide initiative that reduced energy consumption by 30% and increased recycling rates by 50%. Implemented a new composting system in all campus cafeterias, diverting 5 tons of food waste from landfills annually.",
      impact: "Environmental Impact",
    },
  ]

  return (
    <div className="">
      <header className="bg-primary py-4 min-w-full">
        <div className="container">
          <h1 className="display-4 text-white">LIÊN CHI ĐOÀN KHOA CÔNG NGHỆ THÔNG TIN</h1>
        </div>
      </header>

      <main className="container my-5">
        <div className="row">
          <div className="col-lg-9">
            <section className="mb-5">
              <h2 className="fs-1 mb-4">Ban Công tác Đoàn & Phát triển Đảng</h2>
              <div className="row">
                {executiveBoard.members.map((member, index) => (
                  <div className="col-md-4 mb-4" key={index}>
                    <div className="card text-center">
                      <img src={member.avatar} className="card-img-top rounded-circle mx-auto mt-5 d-block" alt={member.name} style={{ width: "100px", height: "100px" }} />
                      <div className="card-body">
                        <h5 className="card-title">{member.name}</h5>
                        <p className="card-text">{member.role}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            {/* <section className="mb-5">
              <h2 className="h4 mb-4">Other Boards</h2>
              {otherBoards.map((board, boardIndex) => (
                <div key={boardIndex}>
                  <h3 className="h5">{board.name}</h3>
                  <div className="row">
                    {board.members.map((member, memberIndex) => (
                      <div className="col-md-4 mb-4" key={memberIndex}>
                        <div className="card text-center">
                          <img src={member.avatar} className="card-img-top rounded-circle mx-auto d-block" alt={member.name} style={{ width: "100px", height: "100px" }} />
                          <div className="card-body">
                            <h5 className="card-title">{member.name}</h5>
                            <p className="card-text">{member.role}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </section> */}

            <section className="mb-5">
                <h2 className="fs-2 mb-4">Các Ban Khác</h2>
                <div className="accordion" id="otherBoardsAccordion">
                    {otherBoards.map((board, boardIndex) => (
                    <div className="accordion-item" key={boardIndex}>
                        <h2 className="accordion-header" id={`heading-${boardIndex}`}>
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${boardIndex}`}
                            aria-expanded={boardIndex === 0 ? "true" : "false"}
                            aria-controls={`collapse-${boardIndex}`}
                            style={{boxShadow: 'none', outline: 'none'}}
                        >
                            {board.name}
                        </button>
                        </h2>
                        <div
                        id={`collapse-${boardIndex}`}
                        className={`accordion-collapse collapse ${boardIndex === 0 ? "show" : ""}`}
                        aria-labelledby={`heading-${boardIndex}`}
                        data-bs-parent="#otherBoardsAccordion"
                        >
                        <div className="accordion-body">
                            <div className="row">
                            {board.members.map((member, memberIndex) => (
                                <div className="col-md-4 mb-4" key={memberIndex}>
                                <div className="card text-center">
                                    <img
                                    src={member.avatar}
                                    className="card-img-top rounded-circle mx-auto d-block mt-5"
                                    alt={member.name}
                                    style={{ width: "100px", height: "100px" }}
                                    />
                                    <div className="card-body">
                                    <h5 className="card-title">{member.name}</h5>
                                    <p className="card-text">{member.role}</p>
                                    </div>
                                </div>
                                </div>
                            ))}
                            </div>
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
                </section>

            <section>
                <h2 className="h2 mb-4">Sự kiện Nổi bật</h2>
                <div className="mb-4">
                    {notableAchievements2.map((achievement, index) => (
                    <div className="card mb-3" key={index}>
                        <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="card-title mb-0">{achievement.title}</h5>
                        <span className="badge bg-secondary">{achievement.year}</span>
                        </div>
                        <div className="card-body">
                        <p className="card-text mb-3">{achievement.description}</p>
                        <div className="d-flex align-items-center text-muted">
                            <i className="bi bi-trophy me-2"></i> {/* Using Bootstrap Icons for the trophy */}
                            <span>{achievement.impact}</span>
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
            </section>

            <section>
                <h2 className="h2 mb-4">Thành tích Nổi bật</h2>
                <div className="mb-4">
                    {notableAchievements.map((achievement, index) => (
                    <div className="card mb-3" key={index}>
                        <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="card-title mb-0">{achievement.title}</h5>
                        <span className="badge bg-secondary">{achievement.year}</span>
                        </div>
                        <div className="card-body">
                        <p className="card-text mb-3">{achievement.description}</p>
                        <div className="d-flex align-items-center text-muted">
                            <i className="bi bi-trophy me-2"></i> {/* Using Bootstrap Icons for the trophy */}
                            <span>{achievement.impact}</span>
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
            </section>

            <section>
              <h2 className="fs-2 mb-4">Tin Tức Liên Chi Đoàn</h2>
              {latestNews.map((news, index) => (
                <div className="card mb-3" key={index}>
                  <div className="card-body">
                    <h5 className="card-title">{news.title}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{news.date}</h6>
                    <p className="card-text">{news.summary}</p>
                  </div>
                </div>
              ))}
            </section>
          </div>

          <aside className="col-lg-3">
            <div className="card mb-4">
              <div className="card-body text-center">
                <h5 className="card-title mb-3">Kênh thông tin</h5>
                <FacebookPage/>
              </div>
            </div>
          </aside>
        </div>
      </main>
    </div>
  );
}