import React, { useEffect, useState } from "react";
import "../css/navbar.css"

const Navbar = () => {
  const [menuItems, setMenuItems] = useState([]);

  // Fetching data using the fetch API
  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await fetch("https://fit.neu.edu.vn/admin/api/navigation/render/1?type=TREE");
        const data = await response.json();
        setMenuItems(data);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    fetchMenuItems();
  }, []);

  const MenuItems = (items, isChild = false) => {
    return items.map((item) => {
      if (item.itemType === "button") {
        // Render a button if the itemType is "button"
        return (
          <li key={item.id} className="nav-item">
            <a href={item.path} className="btn btn-primary navbar-button p-4 rounded-0 d-flex justify-content-center align-items-center">
              {item.icon?.url && (
                <img
                  src={`https://fit.neu.edu.vn/admin/${item.icon.url}`}
                  alt={item.title}
                  style={{ width: '100%', height: '100%', objectFit:'contain'}}
                />
              )}
              {item.icon? "" : item.title}
            </a>
          </li>
        );
      } else if (item.items.length > 0) {
        // Render a dropdown if the item has children
        return (
          <li key={item.id} className="nav-item dropdown">
            <a
              className={`nav-link dropdown-toggle ${isChild ? "fs-5" : "fs-6"}`}
              href={item.path}
              id={`navbarDropdown-${item.id}`}
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {item.title}
            </a>
            <ul className="dropdown-menu rounded-0" aria-labelledby={`navbarDropdown-${item.id}`}>
              {MenuItems(item.items, true)}
            </ul>
          </li>
        );
      } else {
        // Render a regular link
        return (
          <li key={item.id} className="nav-item">
            <a className={`nav-link ${isChild ? "dropdown-item" : ""}`} href={item.path}>
              {item.title}
            </a>
          </li>
        );
      }
    });

  };

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0 navbar-hover" data-wow-delay="0.1s">
      <a href="/" className="navbar-brand d-flex align-items-center px-4 px-xxl-5 px-xl-4 px-lg-3 px-md-2">
        <img src="/img/LogoNEU.png" href="/" alt="" width="48px" height="48px" />
        <img src="/img/LogoFIT.png" width="48px" height="48px" className="ms-1" />
        <div className="d-flex flex-column ms-2 d-lg-none d-xxl-block">
          <h1 className="m-0 fs-4 text-primary">KHOA CÔNG NGHỆ THÔNG TIN</h1>
          <h2 className="m-0 text-dark fs-6">Faculty of Information Technology</h2>
        </div>
      </a>
      <button
        className="navbar-toggler me-4"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav ms-auto p-4 p-lg-0">
          {MenuItems(menuItems)}
        </ul>
      </div>
    </nav>
  );
};
export default Navbar;