import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from "../../utils/config";
import Spinner from '../../containers/Spinner';
import { useParams } from 'react-router-dom';

const styles = `
  #post-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .previous-news img {
    object-fit: cover;
    width: 100%;
    height: 150px; 
  }
  .previous-news .card-title {
    font-size: 1rem;
    line-height: 1.2;
    height: 3.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .previous-news .card-text {
    font-size: 0.8rem;
  }
  #post-content {
    font-size: 1.1rem;  
    font-weight: 500;  
    line-height: 1.6;   
  }

  #post-content p {
    margin-bottom: 1.2em; 
  }

  #post-content strong, 
  #post-content b {
    font-weight: 600;  
  }
`;

const NewsDetails = () => {
  const [newsData, setNewsData] = useState(null);
  const [previousNews, setPreviousNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { slug } = useParams();
  console.log('this is the slug: ', slug);
  useEffect(() => {

    // Fetch current news by slug
    fetch(`${config.API_URL}/api/blogs/slug/${slug}?populate=*`)
      .then(res => res.json())
      .then(res => {
        setNewsData(res.data.attributes);
        document.title = `${res.data.attributes.title}`;
      })
      .catch(err => {
        setError('Failed to fetch news');
      })
      .finally(() => {
        setLoading(false);
      });

    // Fetch previous news
    fetch(`${config.API_URL}/api/blogs?populate=*&pagination[start]=0&pagination[limit]=4&pagination[withCount]=true&sort=createdAt:desc`)
      .then(res => res.json())
      .then(res => {
        setPreviousNews(res.data);
      })
      .catch(err => {
        setError('Failed to fetch previous news');
      });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <style>{styles}</style>

      <div
        className="bg-image"
        style={{
          backgroundImage: `url(${config.API_URL + newsData?.thumbnail?.data.attributes.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '50vh',
          backgroundPositionY: 'center',
        }}
      >
        <div className="mask d-flex align-items-end" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', height: '100%' }}>
          <div className="d-flex align-items-end h-100">
            <div className="title m-5">
              <h1 className="px-5 text-white">{newsData.title}</h1>
              <span className="text-body-secondary p-5 text-white fs-5">
                <i className="fa-regular fa-clock text-light"></i>
                <span className='text-light' style={{ paddingLeft: '10px' }}>
                  {moment(newsData.createdAt).format('DD [tháng] MM YYYY, HH:mm')}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <section className="sidebar-page dt-section">
        <div className="container my-5">
          <div className="row s-25">
            <div className="col-lg-9 col-12 pr-lg-5" id="post-content">
              <p dangerouslySetInnerHTML={{ __html: newsData.content }}></p>
            </div>

            <div className="sidebar-block col-lg-3 col-12">
              <div className="previous-news">
                <h2>Tin tức khác</h2>
                <hr className="my-4" />
                <div className="container mt-3">
                  <div className="row" id="previous-news">
                    {previousNews.map((news, index) => (
                      <div key={index} className="card border-0 mb-3 px-2 col-lg-12 col-md-3">
                        <div className="ratio ratio-16x9">
                          <a href={`/post/${news.attributes.slug}`}>
                            <img
                              className="card-img-top img-fluid rounded-0"
                              src={config.API_URL + news?.attributes?.thumbnail?.data.attributes.formats?.thumbnail.url}
                              alt={news.attributes.title}
                            />
                          </a>
                        </div>
                        <div className="card-body px-0">
                          <a href={`/post/${news.attributes.slug}`}>
                            <h6 className="card-title">{news.attributes.title}</h6>
                          </a>
                          <p className="card-text">
                            <small className="text-muted">
                              {moment(news.attributes.createdAt).format('DD [tháng] MM YYYY, HH:mm')}
                            </small>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="tag mt-5 mb-4">
                <h2>Phân loại</h2>
                <hr className="my-4" />
                <h6>
                  <a href="/vi/blog-category/tin-tong-hop" title="Tin Tổng hợp">
                    Tin tổng hợp
                  </a>
                  <span> (5)</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewsDetails;