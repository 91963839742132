import React, { useEffect } from "react";
import Service from "../components/home/Service";
import Carousel from "../components/home/Carousel";
import News from "../components/home/News";
import Facts from "../components/home/Facts";
import Partners from "../components/home/Partners";
import Enrollment from "../components/home/Enrollment";
import StudentActivity from "../components/home/StudentActivity";
import '../css/style.css'
function Home() {
  useEffect(() => {
    document.title = "Trang chủ | Khoa Công nghệ thông tin";
  }, []);
  return (
    <div>
      <Carousel />

      <Service />

      <News />

      <Facts />

      <Enrollment />

      <Partners />

    </div>
  );
}

export default Home;
