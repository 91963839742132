import React from 'react'

function Facts() {
  return (
    <div class="container-fluid fact bg-dark my-5 py-5">
        <div class="container">
        <div class="row g-4">
            <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
            <i class="fa fa-calendar-check fa-2x text-white mb-3"></i>
            <h2 class="text-white mb-2" data-toggle="counter-up">20</h2>
            <p class="text-white mb-0">Năm kinh nghiệm đào tạo</p>
            </div>
            <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
            <i class="fa fa-book-bookmark fa-2x text-white mb-3"></i>
            <h2 class="text-white mb-2" data-toggle="counter-up">5</h2>
            <p class="text-white mb-0">Chương trình đào tạo</p>
            </div>
            <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
            <i class="fa fa-users fa-2x text-white mb-3"></i>
            <h2 class="text-white mb-2" data-toggle="counter-up">892</h2>
            <p class="text-white mb-0">Sinh viên đã tốt nghiệp</p>
            </div>
            <div class="col-md-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s">
            <i class="fa fa-briefcase fa-2x text-white mb-3"></i>
            <h2 class="text-white mb-2" data-toggle="counter-up">96</h2>
            <p class="text-white mb-0">% Sinh viên ra trường có việc làm</p>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Facts